<template>
  <div>
   
      <b-row>

        <b-card  v-for="item in menu" :key="item.meal">
    <b-row  >

      <!-- text and button -->
      <b-col
        sm="12"
        order-sm="1"
        order="2"
        class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      >
        <div>
          <h2 class="font-weight-bolder mb-25">
            {{ item.menuItem }}
          </h2>
          <b-card-text class="font-weight-bold mb-2">
            Avg Sessions
          </b-card-text>

          <h5 class="font-medium-2">
            <span class="text-success mr-50"> {{ item.calories }}</span>
            <span>Calories</span>
          </h5>
        </div>

        
      </b-col>

      <!-- dropdown and chart -->
      
    </b-row>
    <hr>

    
  </b-card>
        
      </b-row>
   
  </div>
</template>
<script>
import { ref, computed } from "@vue/composition-api";
import {
  BFormFile,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BCol,
  BRow,BProgress, BCardText,
} from "bootstrap-vue";
export default {
  name: "meal-item",
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BCol,
    BRow,BProgress, BCardText,
  },
  props: {
    menu: [],
  },
  setup(props) {},
};
</script>
<style>

</style>
