import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'Date', sortable: true },
    
   
     
    { key: 'Menu',   label:"Menu", sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null);
  
  const getMenuToday = ref(null);
  var today=new Date();
  var month=today.getMonth();
  var year=today.getFullYear();
//console.log("current year: "+ year+" month : "+ month);

  const currentYear = ref(year);
  const currentMonth = ref(month);



  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter,currentYear,currentMonth, statusFilter], () => {
    refetchData();
  })

  const fetchMonthlyMenu = (ctx, callback) => {
    store
      .dispatch('app-daily-menu/fetchMonthlyMenu', {
        y:currentYear.value,
        m:currentMonth.value+1,
    
      })
      .then(response => {
        const { data } = response.data;

        const arr =   Object.entries(data);
        //console.log("Received Menu ",arr);
      
         
        callback(arr)
        totalUsers.value = data.length;

      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchTodayMenu = (ctx, callback) => {
    store
      .dispatch('app-daily-menu/fetchTodayMenu', {
        y:currentYear.value,
        m:currentMonth.value+1,
    
      })
      .then(response => {
        const { data } = response.data;

         
         
         
        //console.log("getMenuToday ",data);
        getMenuToday.value=data;


      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const getMonthName = monthIndedx => {

   
  return monthNames[monthIndedx] 
}

  const resolveDate = menuListItem => {

    let values = Object.values(menuListItem);
    return values[0]
  }

  const increaseMonth =()=> {

  currentMonth.value=currentMonth.value+1;
  if (currentMonth.value==12)
  {
    currentYear.value=currentYear.value+1;
    currentMonth.value=0;
  }
   
  }
  const decreaseMonth=() => {
    currentMonth.value=currentMonth.value-1;
    if (currentMonth.value==-1)
    {
      currentYear.value=currentYear.value-1;
      currentMonth.value=11;
    }
   
  }

  const resolveMenuList = menuListItem => {

    let values = Object.values(menuListItem);
    return values[1]
  }



  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status ===null) return     'primary'
   return 'success'
     
  }
  const resolveUserStatusVariantText = status => {
    if (status === null) return 'primary'
   
    return status;
  }

  const resolveUserRolesArray= roles => {
   //console.log(roles);
    var rolesArray = [];
    if (roles.length>0) {
    for (var i = 0; i < roles.length; i++) {
      rolesArray.push(roles[i].description);
    }
      

    }
    return rolesArray;
  }


  return {
    increaseMonth,
    decreaseMonth,
    getMonthName,
    currentMonth,
    currentYear,
    getMenuToday,
    fetchTodayMenu,
    resolveDate,
    resolveMenuList,
    fetchMonthlyMenu,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,
    resolveUserRolesArray,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
