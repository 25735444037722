<template>
    <div>
      <b-card>
        <b-card-title style="margin-bottom: 0px">
          <b-row>
            <b-col> Today's Menu </b-col>
           
          </b-row>
        </b-card-title>
  
        <VMealItem :menu="getMenuToday" :key="JSON.stringify(getMenuToday)" />
      </b-card>
    </div>
  </template>
  <script>
  import ExcelImport from "./tools/ExcelImport"; 
  import store from "@/store";
  import VMealItem from "./components/VMealItem.vue";
  import { ref,computed, onUnmounted } from '@vue/composition-api'
  import useUsersList from './useMenuList'

  import {

    BFormFile,
    BCard,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BCol,
    BRow,
  } from "bootstrap-vue";
  export default {
    components: {
      BFormFile,
      VMealItem,
      BCard,
      BCardBody,
      BCardFooter,
      BCardTitle,
      BCol,
      BRow,
    },
    setup() {

      const USER_APP_STORE_MODULE_NAME = 'app-daily-menu'
  
  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })


      let onPresentationFileSelected = async function (input) {
        let menuData = await ExcelImport.Excel2Json(input);
        store.dispatch("hr-menu/getImportedMenu", menuData);
      };
      const getMenusTsoday = computed(() => {
        //this part going to change when endpoints come-up
  
        let monthlyList = store.getters["hr-menu/getImportedMenu"];
        if (monthlyList != null) {
          let today = new Date();
          let todayMeal = monthlyList.filter(
            (x) => x.date.getDate() == today.getDate()
          );
      if (todayMeal==null) todayMeal=[];
      
          todayMeal[0].menu = todayMeal[0].menu.filter(
            (x) => x.meal != undefined
          );
          return todayMeal[0].menu;
        } else {
          return null;
        }
      });

      const {fetchTodayMenu,getMenuToday}    = useUsersList();
      
      const b=fetchTodayMenu();
     //console.log("ddd", b);
      return {
        onPresentationFileSelected,
        getMenuToday,
      };
    },
  };
  </script>
  <style></style>
  