const ExcelJS = require ('exceljs');
let calculateMaxRowLength=function(rows){
    let maxRowLength;
for(let row of rows){
 maxRowLength = maxRowLength>row.length?maxRowLength:row.length
}
return maxRowLength;
}
async function Excel2Json(excelFile){
    const excelJsonArray=[];
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    let mealArray=[]
    await new Promise((resolve)=>{
        reader.readAsArrayBuffer(excelFile)
        reader.onload = () =>{
            const buffer = reader.result;
            wb.xlsx.load(buffer).then(workbook=>{
                resolve(workbook);
            })
        }})
    const ws =  wb.getWorksheet('Sheet1')
    ws.eachRow((row,rowIndex)=>{
        if(rowIndex>1){
            excelJsonArray.push(row.values)
        }
    })
    let maxLenght=calculateMaxRowLength(excelJsonArray)
    for(let row of excelJsonArray){
        let menu=[]
        for(let i=3;i<maxLenght;i=i+2){
            menu.push({meal:row[i-1],calories:row[i]})

        }
        mealArray.push({date:row[1],menu:menu})
    }
   
    return  mealArray;
}
export default {
    Excel2Json
}